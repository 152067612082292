<template>
  <div>
    <v-row> 병원용 이미지/영상 업로드 </v-row>
    <v-row>
      <PrepareFilesUpload
        ref="refPrepareFilesUpload"
        fileGbn="contents"
        :propsUploadedFiles="uploadFiles"
        @checkTargetFiles="checkTargetFiles"
      />
    </v-row>
    <v-row>
      <v-col cols="12" md="12" align="center">
        <v-btn color="primary" depressed large @click="saveMultiFiles">
          업로드파일 저장
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PrepareFilesUpload from "@/components/commonV2/ui/PrepareFilesUpload.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: {
    PrepareFilesUpload,
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      // clinicData: null,
      uploadFiles: [],
      uploadFilesModel: modelService.uploadFilesModel(),
    };
  },
  methods: {
    // 업로드 된 파일 정보 가져오기
    async _getUploadFiles() {
      // this.clinicData = clinicData;
      this.uploadFilesModel.endpoint = "uploadFiles";
      this.uploadFilesModel.hospital_id = this.clinicData.hospital_id;
      this.uploadFilesModel.fileGbn = "contents";
      await this.getDataQuery(this.uploadFilesModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        // console.table(res);
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              true
            ),
          }));
        }
        this.uploadFiles = res;
      });
    },
    checkTargetFiles(e, removeOption) {
      this.$emit("checkTargetFiles", e, removeOption);
      this.uploadFilesModel.uploadFiles = e;
      if (removeOption === "removeClick") this.saveMultiFiles();
    },
    // 여러파일 처리
    saveMultiFiles() {
      if (
        this.uploadFilesModel.uploadFiles === null ||
        this.uploadFilesModel.uploadFiles.length === 0
      )
        return;

      this.uploadFilesModel.endpoint = "uploadMultiFiles";
      this.uploadFilesModel.fileGbn = "contents"; // 전체삭제를 위해 필요
      this.uploadFilesModel.hospital_id = this.clinicData.hospital_id;
      // this.uploadFilesModel.locale = this.$i18n.locale;
      // console.log("this.uploadFilesModel", this.uploadFilesModel);
      // return;
      this.saveDataQuery(this.uploadFilesModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.$emit("saveComplete");
        this.$refs.refClinicContentsUpload._getUploadFiles(this.clinicData);
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.contentsInfos_btnBox {
  display: block !important;
  text-align: center;
}
</style>