<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <div class="tableTitle">{{ title }}</div>

      <ClinicImageUpload ref="refClinicImageUpload" :clinicData="clinicData" />

      <div class="vGap" />

      <v-row>
        <v-col>
          <!-- :rules="[filedRules.required]" -->
          <v-text-field
            v-if="clinicData"
            ref="refAddUrl"
            label="홍보영상 URL"
            placeholder="https://www.youtube.com/fjkdsajfldsafj"
            v-model="clinicData.addUrl"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" align="center">
          <v-btn color="primary" depressed large @click="saveAddUrl('Y')">홍보영상정보 저장</v-btn>
          <!-- <v-btn v-else color="danger" depressed large @click="saveAddUrl('N')">홍보영상정보 삭제</v-btn> -->
        </v-col>
      </v-row>

      <div class="vGap" />

      <ClinicContentsUpload
        ref="refClinicContentsUpload"
        :clinicData="clinicData"
      />
    </v-card>
  </v-tab-item>
</template>
<script>
import { mapActions } from "vuex";
import ClinicImageUpload from "./contentsInfos/ClinicImageUpload.vue";
import ClinicContentsUpload from "./contentsInfos/ClinicContentsUpload.vue";
const modelService = require("@/utils/dataModel");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    ClinicImageUpload,
    ClinicContentsUpload,
  },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  data() {
    return {
      title: "병원이미지(Main/입구이미지) 관리",
      // reqData: modelService.hospitalModel(),
      filedRules: rulesService.filedRules(),
    };
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        this.$refs.refClinicImageUpload._getData();
        this.$refs.refClinicContentsUpload._getUploadFiles();
      }, 300);
    },
    saveAddUrl(opt) {
      // 필수
      const $h = this.$helper;
      this.clinicData.addUrlUseYN = opt;      
      // if(this.clinicData.addUrl === null || this.clinicData.addUrl === "") {
      //   $h.showTostMessage("", "홍보영상Url을 입력해 주세요", "error");
      //   this.$refs.refAddUrl.focus();
      //   return;
      // }

      if(opt === 'N') {
        if (!confirm(`홍보영상정보를 삭제하시겠습니까?`)) return;
      }

      this.clinicData.endpoint = "hospitalAddUrl";
      this.saveDataQuery(this.clinicData).then((res) => {
        if(res === undefined) return;
        console.log('res', res);
        this.clinicData.addUrlUseYN = "Y"
        this.clinicData.addUrl = res.addUrl;
        this.$forceUpdate();
        $h.showTostMessage("", $h.commonMessage("S"), "success");
      })
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="">
</style>